let api_urls = {
  client_id: '2',
  client_secret: 'dhGLOnQlLZhDPJmRaNXXJcAOEcDZnqMtm7VGpc5t',
  base_url: 'https://homolog.api.iclinicpay.com.br/',
  base_api: 'https://homolog.api.iclinicpay.com.br/api/v1/',
  base_api_v2: 'https://homolog.api.iclinicpay.com.br/api/v2/',
  receipt: 'https://homolog.receipt.iclinicpay.com.br/'
};

export const environment = {
  name: 'homolog',
  production: false,
  title: 'HOMOLOGAÇÃO',
  api: api_urls,
  cookieConsent: 'https://homolog.cookie-consent.iclinicpay.com.br/cookieConsent.js',
  snowplow_collector_url: "",
  snowplow_app_id: 'medicinae_frontend_organizacao',
  intercom_app_id: "gl163exs",
  minimum_installment_value : 5,
  featureFlags: {
    modalityScreenAvaliable: false,
    maintenanceAlert: false,
    maintenancePage: false
  }
};
